import { Component, Input } from '@angular/core';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Basket } from '@app/models/basket';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { BasketService } from '@app/api/basket.service';
import { DealTracking } from '@app/models/deals/DealTracking';
import { BasketDealView } from '@app/models/basket/basket-deal-view';

@Component({
  selector: 'app-basket-deal-item',
  styleUrls: ['./basket-deal-item.component.scss', './../../shared-basket-styles.scss'],
  templateUrl: './basket-deal-item.component.html'
})
export class BasketDealItemComponent extends AndroWebCoreComponent {
  @Input() public item: BasketDealView;
  @Input() public basketIsModifiable: boolean;
  @Input() public showImages: boolean = true;

  @Input('currentBasket') private _currentBasket: Basket;

  private _addAnotherButtonsDeals: { [id: string]: number };

  constructor(
    private _basketService: BasketService,
    private _analyticsService: AnalyticsService
  ) {
    super();
    this._addAnotherButtonsDeals = {};
  }

  /**
   * removes an deal from the basket
   * @param dealIndex - the index of the deal
   */
  public async removeDealItem(): Promise<void> {
    await this._basketService.deleteDealFromBasketAsync(this.item.id);
    this.trackRemoveDealItem();
    const dealItemId: string = this.item.dealId;

    if (this._addAnotherButtonsDeals[dealItemId]) {
      this._addAnotherButtonsDeals[dealItemId] = null;
    }

    if (!this._currentBasket.UnlockedProductIds || !this._currentBasket.UnlockedProductIds.includes(dealItemId)) {
      return;
    }

    const basket: Basket | null = await this._basketService.deleteVoucherFromBasket(this._currentBasket.Id);

    if (basket) {
      basket.WantedTimeUtc = this._currentBasket.WantedTimeUtc;
      this._currentBasket = basket;
    }
  }

  /**
  * expands or collapses a deal
  * @param dealId - the id of the deal
  * @param dealIndex - the index of the deal
  */
  public toggleDealExpansion(): void {
    this.item.expanded = !this.item.expanded;
  }

  /**
   * tracks the removal of a deal
   */
  private trackRemoveDealItem(): void {
    const items: { [key: string]: string | number }[] = [
      {
        'item_id': this.item.dealId,
        'item_name': this.item.name,
        'price': this.item.price,
        'quantity': 1
      }
    ];

    const track: DealTracking = {
      currency: 'GBP',
      items,
      transactionId: this.item.id,
      value: this.item.price
    };

    this._analyticsService.trackAddRemoveDealToBasket(false, track);
  }
}
