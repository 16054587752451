import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '@app/shared/services/analytics.service';

@Injectable()
export class ApiTimesInterceptor implements HttpInterceptor {
  constructor(private analyticsService: AnalyticsService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeStart = new Date().getTime();
    const localAnalyticsService = this.analyticsService;

    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          try {
            localAnalyticsService.trackPerformance('Api call', `${request.method}_${request.urlWithParams}`, (new Date().getTime() - timeStart));
          } catch (e) {
            if (e.message !== 'gtag is not defined') {
              console.log(e);
            }
          }

          return event;
        }));
  }
}
