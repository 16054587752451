import { Injectable } from '@angular/core';
import { AllergenDetails } from '@app/models/allergen-details';
import { Basket } from '@app/models/basket';
import { BasketItem } from '@app/models/basket/basket-item';
import { BasketDealItemView } from '@app/models/basket/basket-deal-item-view';
import { BasketDealView } from '@app/models/basket/basket-deal-view';
import { BasketItemModifierChanges } from '@app/models/basket/basket-item-modifier-changes';
import { BasketItemView } from '@app/models/basket/basket-item-view';
import { Charge } from '@app/models/Charge';
import { ChargeTypes } from '@app/models/charge-types';
import { DealLineType } from '@app/models/menu/deal-line-type.enum';
import { IDictionary } from '@app/models/IDictionary';
import { ModifierType2 } from '@app/models/menu/modifier-type';
import { Modifiers } from '@app/models/Modifiers';
import { OccasionWeekAvailability } from '@app/models/menu/occasion-week-availability';
import { OrderOccasion } from '@app/models/order-occasion';
import { QuantityOf } from '@app/models/quantity-of';
import { WeekPeriod } from '@app/models/week-period';
import { WeekPeriods } from '@app/models/week-periods';
import { isAvailableForDate } from '@app/shared/utils/date-utils';
import { AllergenSummary } from '@app/models/menu/allergen-summary';
import { AllergenSummaryData } from '@app/models/menu/allergen-summary-data';
import { Availability } from '@app/models/menu/availability';
import { Deal } from '@app/models/menu/deal';
import { Menu } from '@app/models/menu/menu';
import { MenuSection } from '@app/models/menu/menu-section';
import { OccasionPrice } from '@app/models/menu/occasion-price';
import { Product } from '@app/models/menu/product';
import { ProductBase } from '@app/models/menu/product-base';
import { ProductVariant } from '@app/models/menu/product-variant';
import { Variant } from '@app/models/menu/variant';
import { ProductOptions } from '@app/models/menu/product-options';
import { CachedMenu } from '@app/models/menu/cached-menu';
import { BasketDeal } from '@app/models/basket/basket-deal';

@Injectable({
  providedIn: 'root'
})
export class MenuHelperService {
  constructor() { }

  private availableForTime(weekAvailability: WeekPeriods, wantedTime: Date): boolean {
    return weekAvailability.Values.some((availability: WeekPeriod) => isAvailableForDate([availability], wantedTime));
  }

  public availableForOccasion(values: OccasionWeekAvailability[], occasion: OrderOccasion, wantedTime: Date): boolean {
    return !values || values?.some((value) => this.availableForOccasionAndTime(value, occasion, wantedTime));
  }

  public areVariantsAvailableForOccasion(variants: Variant[], occasion: OrderOccasion, wantedTime: Date): boolean {
    return variants.some((variant) =>
      variant.Prices.some((x) => x.Occasion === occasion) && (!variant.Availability || this.availableForOccasion(variant.Availability.Values, occasion, wantedTime))
    );
  }

  public getProductsForSectionAndOccasion(section: MenuSection, occasion: OrderOccasion, products: Product[]): Product[] {
  // Check if the section is available for the given occasion
    if (this.isAvailableForOccasion(section.Availability, occasion)) {
      return products.filter((product) => section.Products.includes(product.Id)
      && product.Variants.some((x: Variant) => x.Prices.find((y: OccasionPrice) => y.Occasion === occasion)?.Amount > 0));
    }

    // Return an empty array if section is not available for the occasion
    return [];
  }

  public getDealsForSectionAndOccasion(section: MenuSection, occasion: OrderOccasion, deals: Deal[]): Deal[] {
  // Check if the section is available for the given occasion
    if (this.isAvailableForOccasion(section.Availability, occasion)) {
      return deals.filter((deal: Deal) =>
        section.Deals.includes(deal.Id) && deal.Occasions.some((x: OrderOccasion) => x === occasion)
      );
    }

    // Return an empty array if section is not available for the occasion
    return [];
  }

  public getProductSections(menu: Menu, occasion: OrderOccasion): MenuSection[] {
    return menu.Sections.filter((section: MenuSection) =>
      !section.Hidden
        && (this.getProductsForSectionAndOccasion(section, occasion, menu.Products).length > 0
        || this.getDealsForSectionAndOccasion(section, occasion, menu.Deals).length > 0)
    );
  }

  /**
   * Returns the option names for the given variant.
   * @param variant
   * @param options
   */
  public getVariantOptionsPathText(variant: Variant, options: IDictionary<ProductBase>) {
    return variant.OptionsPath
        .map((id: string) => options[id]?.Name)
        .filter((name: string) => name)
        .join(' - ');
  }

  /**
   * Returns true if the product is simple (no modifiers + only one variant).
   * @param productVariants
   */
  public isProductSimple(productVariants: Variant[]): boolean {
    return productVariants.length === 1 && !productVariants[0].Modifiers;
  }

  public getNutritionSummary(variants: Variant[], showMax: boolean): string {
  // Extract non-null nutrition objects from the variants
    const nutrition = variants
        .map((variant) => variant.Nutrition)
        .filter((x) => !!x);

    if (nutrition.length === 0) {
      return '';
    }

    if (nutrition.length === 1) {
      const singleNutrition = nutrition[0];
      return `${singleNutrition.Calories} kcal • serves ${singleNutrition.NumberOfPortions}`;
    }

    const minCalories = Math.min(...nutrition.map((n) => n.Calories));
    const maxCalories = showMax ? `-${Math.max(...nutrition.map((n) => n.Calories))}` : '';
    const minPortions = Math.min(...nutrition.map((n) => n.NumberOfPortions));
    const maxPortions = showMax ? `-${Math.max(...nutrition.map((n) => n.NumberOfPortions))}` : '';

    return `From ${minCalories}${maxCalories} kcal • serves ${minPortions}${maxPortions}`;
  }

  public getPriceForOccasion(Prices: OccasionPrice[], occasion: OrderOccasion): number {
    return Prices.find((x: OccasionPrice) => x.Occasion === occasion)?.Amount;
  }

  public getDefaultModifiersPriceForOccasion(modifiers: Modifiers, variants: IDictionary<ProductVariant>, occasion: OrderOccasion): number {
    return !modifiers
      ? 0
     : modifiers.Default.reduce((acc, modifier) => acc + (!variants[modifier] ? 0 : this.getPriceForOccasion(variants[modifier].Variant.Prices, occasion) ?? 0), 0);
  }

  public getPriceSummaryForVariants(variants: Variant[], occasion: OrderOccasion, includeFromText: boolean = true): string {
    if (!variants || variants.length === 0) {
      return null;
    }

    const min: number = this.getMinPriceForVariants(variants, occasion);

    if (min === this.getMaxPriceForVariants(variants, occasion)) {
      return `£${min.toFixed(2)}`;
    }

    return `${includeFromText ? 'from ' : ''}£${min.toFixed(2)}`;
  }

  public getPriceSummaryForDeal(deal: Deal, occasion: OrderOccasion, menu: Menu, productVariants: IDictionary<ProductVariant>): string {
    const getPricePrefix = () => deal.Lines.every((x) => x.Type === DealLineType.Fixed) ? '' : 'from ';
    const min: number = this.getMinPriceForDeal(deal, occasion, menu, productVariants);

    return `${getPricePrefix()} £${min.toFixed(2)}`;
  }

  public mapAllergenToKnownAllergen(knownAllergens: IDictionary<string>, key: string): string {
    return knownAllergens[key] ? knownAllergens[key].replace('cereals-', '') : key;
  }

  public getAllergenSummary(map: IDictionary<string>, allergens: AllergenDetails): AllergenSummary {
    if (!allergens) {
      return null;
    }

    let summary = '';
    const getFriendlyValue = (values: string[]) => values.map((x) => this.mapAllergenToKnownAllergen(map, x)).join(', ');

    if (allergens.Contains && allergens.MayContain) {
      summary = `${getFriendlyValue(allergens.Contains)}, (May Contain: ${getFriendlyValue(allergens.MayContain)})`;
    } else if (allergens.Contains) {
      summary = getFriendlyValue(allergens.Contains);
    } else {
      summary = getFriendlyValue(allergens.MayContain);
    }

    const result: AllergenSummary = {
      summary,
      showMayContainMessage: allergens.MayContain && allergens.MayContain.length > 0
    };

    if (allergens.Contains || allergens.MayContain) {
      const data: AllergenSummaryData = {};

      if (allergens.Contains) {
        data.contains = allergens.Contains.map((x: string) => ({ imageSource: this.getAllergenImage(x, 'red'), name: this.mapAllergenToKnownAllergen(map, x) }));
      }

      if (allergens.MayContain) {
        data.mayContain = allergens.MayContain.map((x: string) => ({ imageSource: this.getAllergenImage(x, 'amber'), name: this.mapAllergenToKnownAllergen(map, x) }));
      }

      result.data = data;
    }

    return result;
  }

  public getApplicableProductOptions(productOptions: ProductOptions[], variants: Variant[]): ProductOptions[] {
    const result: ProductOptions[] = [];

    for (const optionId of variants.flatMap((x) => x.OptionsPath)) {
      const options = productOptions.find((x) => x.Options.some((y) => y.Id === optionId));

      if (!options || result.some((x) => x.Id === options.Id)) {
        continue;
      }

      result.push(options);
    }

    return result.map((x: ProductOptions) => ({
      ...x,
      Options: x.Options.filter((y: ProductBase) => variants.some((z: Variant) => z.OptionsPath.includes(y.Id)))
    }));
  }

  public getModifierTypesForBasketItem(productVariant: ProductVariant, modifiers: QuantityOf[], variantsById: IDictionary<ProductVariant>): BasketItemModifierChanges {
    if (!productVariant) {
      return null;
    }

    const addons: ModifierType2[] = [];
    const removes: ModifierType2[] = [];
    const modifierIds = modifiers.map((x: QuantityOf) => x.Item);

    // Removed modifiers
    productVariant.Variant.Modifiers?.Default
        .filter((id: string) => !modifierIds.includes(id))
        .forEach((id: string) => {
          const product = variantsById[id];

          if (product) {
            removes.push({
              quantity: 1,
              name: product.Product.Name
            });
          }
        });

    const uniqueModifiers: QuantityOf[] = [];

    modifiers.forEach((modifier: QuantityOf) => {
      const index = uniqueModifiers.findIndex((x: QuantityOf) => x.Item === modifier.Item);

      if (index > -1) {
        uniqueModifiers[index].Quantity += 1;
      } else {
        uniqueModifiers.push(modifier);
      }
    });

    uniqueModifiers.forEach((modifier) => {
      const isDefault = productVariant.Variant.Modifiers.Default.includes(modifier.Item);
      const test = variantsById[modifier.Item];

      if (isDefault && modifier.Quantity === 1) {
        return;
      }

      if (isDefault) {
        modifier.Quantity--;
      }

      addons.push({
        name: test.Product.Name,
        quantity: modifier.Quantity
      });
    });

    return { addons, removes };
  }

  public getBasketItemViews(basket: Basket, menu: CachedMenu): { deals: BasketDealView[]; products: BasketItemView[]; } {
    const deals: BasketDealView[] = basket.Deals?.map((deal: BasketDeal) => this.mapBasketDealToDealView(deal, menu, basket.UnlockedProductIds))
        .sort((a: BasketDealView, b: BasketDealView) => a.name.localeCompare(b.name));

    const products: BasketItemView[] = basket.Items?.map((item: BasketItem) => this.mapBasketItemToBasketItemView(item, menu))
        .filter((x: BasketItemView) => x);

    return { deals, products };
  }

  /**
 * Calculate the minimum price for a deal for a specific occasion and menu.
 * @param {Occasion} occasion
 * @param {Menu} menu
 * @returns {number} Min price for the deal.
 */
  public getMinPriceForDeal(deal: Deal, occasion: OrderOccasion, menu: Menu, productVariants: IDictionary<ProductVariant>): number {
    let result = 0;

    for (const line of deal.Lines) {
      let linePrice = 0;

      // Find the variant list for the line
      const variantsList = menu.VariantLists.find((vl) => vl.Id === line.VariantList);
      if (variantsList) {
        if (line.Type === DealLineType.Fixed) {
          linePrice = line.Value;
        } else {
          let minItemPrice = Number.MAX_VALUE;

          // Iterate through all variants in the list
          for (const variantId of variantsList.Variants) {
            const variant = productVariants[variantId];
            if (!variant) {
              continue;
            }

            // Get the minimum price for the variant's product
            const price = this.getMinPriceForVariants(variant.Product.Variants, occasion);
            if (price < minItemPrice) {
              minItemPrice = price;
            }
          }

          // Adjust price based on the deal line type
          switch (line.Type) {
            case DealLineType.Percent:
              linePrice = minItemPrice * (line.Value / 100);
              break;

            case DealLineType.Discount:
              linePrice = minItemPrice - line.Value;
              break;
          }
        }
      }

      result += linePrice;
    }

    // Return result or 0 if no price was calculated
    return result > 0 ? result : 0;
  }

  private isAvailableForOccasion(availability: Availability, occasion: OrderOccasion): boolean {
    if (!availability) {
      return true;
    }

    const sectionAvailability = availability.Values.find((x: OccasionWeekAvailability) => x.Occasion === occasion);
    return sectionAvailability === null || !sectionAvailability.Disabled;
  }

  private availableForOccasionAndTime(value: OccasionWeekAvailability, occasion: OrderOccasion, wantedTime: Date): boolean {
    return !value.Disabled && value.Occasion === occasion && this.availableForTime(value.WeekAvailability, wantedTime);
  }

  private getMinPriceForVariants(variants: Variant[], occasion: OrderOccasion): number {
    const prices = variants
        .map((variant) => this.getPriceForOccasion(variant.Prices, occasion))
        .filter((x) => x !== null && x !== 0);

    return Math.min(...prices);
  }

  private getMaxPriceForVariants(variants: Variant[], occasion: OrderOccasion): number {
    const prices = variants
        .map((variant) => variant.Prices.find((x) => x.Occasion === occasion)?.Amount)
        .filter((x) => x !== null && x !== 0);

    return Math.max(...prices);
  }

  private getAllergenImage(key: string, colour: 'red' | 'amber') {
    let allergenId: string;
    switch (key) {
      case 'mil':
        allergenId = 'dairy';
        break;
      case 'cer':
      case 'cer-bar':
      case 'cer-kam':
      case 'cer-oat':
      case 'cer-ogf':
      case 'cer-rye':
      case 'cer-spe':
      case 'cer-whe':
        allergenId = 'glu';
        break;
      default:
        allergenId = key;
    }

    return `assets/allergens/${allergenId}/${allergenId}_${colour}_100x100.png`;
  }

  /**
   * Maps a basket deal to a deal view.
   * @param basketDeal
   * @param menu
   * @param unlockedProductIds
   */
  private mapBasketDealToDealView(basketDeal: BasketDeal, menu: CachedMenu, unlockedProductIds?: string[]): BasketDealView {
    const deal: Deal = menu.menu.Deals.find((d: Deal) => d.Id === basketDeal.DealId);

    return {
      id: basketDeal.Id,
      name: deal.Name,
      imageUrl: deal.ImageBase ? `${deal.ImageBase}/landscape-small.jpg` : null,
      isInVoucher: unlockedProductIds?.includes(basketDeal.DealId) ?? false,
      expanded: false,
      items: this.getBasketDealItemViews(basketDeal.Items, menu),
      price: this.calculateDealPrice(basketDeal),
      hasIssues: basketDeal.Items.some((x: BasketItem) => x.Issues?.length > 0),
      dealId: basketDeal.DealId,
      occasions: deal.Occasions
    };
  }

  /**
   * returns basket deal item view from basket items.
   * @param items
   * @param menu
   */
  private getBasketDealItemViews(items: BasketItem[], menu: CachedMenu): BasketDealItemView[] {
    if (!items) {
      return [];
    }

    return items
        .map((item: BasketItem) => {
          const product: ProductVariant = menu.variantsById[item.Product.Item];

          if (!product) {
            return null;
          }

          return {
            name: product.Product.Name,
            imageUrl: product.Product.ImageBase ? `${product.Product.ImageBase}/landscape-small.jpg` : null,
            issues: item.Issues,
            modifiers: this.getModifierTypesForBasketItem(menu.variantsById[item.Product.Item], item.Modifiers, menu.variantsById)
          };
        })
        .filter((x: BasketDealItemView) => x);
  }

  /**
   * Maps a basket item to a basket item view.
   * @param item
   * @param menu
   */
  private mapBasketItemToBasketItemView(item: BasketItem, menu: CachedMenu): BasketItemView {
    const product: ProductVariant = menu.variantsById[item.Product.Item];

    if (!product) {
      return null;
    }

    return {
      id: item.Id,
      name: product.Product.Name,
      imageUrl: product.Product.ImageBase ? `${product.Product.ImageBase}/landscape-small.jpg` : null,
      quantity: item.Product.Quantity,
      options: product.Variant.OptionsPath.length > 1 ? this.getVariantOptionsPathText(product.Variant, menu.optionsById) : null,
      modifiers: this.getModifierTypesForBasketItem(menu.variantsById[item.Product.Item], item.Modifiers, menu.variantsById),
      price: item.Charges.filter((x: Charge) => x.ChargeType === ChargeTypes.ProductCharge).reduce((a, b) => a + b.Total, 0),
      issues: item.Issues,
      occasions: product.Variant.Prices.map((x: OccasionPrice) => x.Occasion)
    };
  }

  /**
  * calculates the combined price of all modifiers on a deal
  * @param dealItems - the items (lines) on a deal
  */
  private calculateDealModifiersTotal(dealItems: BasketItem[]): number {
    let number = 0;

    dealItems?.forEach((item: BasketItem) => {
      item.Charges.filter((c: Charge) => c.ChargeType === ChargeTypes.ChargeableModifier)
          .forEach((c: Charge) => number += c.Total);
    });

    return number;
  }

  private calculateDealPrice(deal: BasketDeal): number {
    let total = this.calculateDealModifiersTotal(deal.Items) ?? 0;

    deal.Items
        .filter((x: BasketItem) => x.Charges?.length > 0)
        .forEach((item: BasketItem) => {
          item.Charges
              .filter((c: Charge) => c.ChargeType === ChargeTypes.ProductCharge)
              .forEach((c: Charge) => total += c.Total);
        });

    return total;
  }
}
