import { WeekPeriod } from '@app/models/week-period';

// Helper function to convert `{day}.{hour}:{minute}:{second}` or `{hour}:{minute}:{second}` to milliseconds from start of the week
function parseTimeSpan(timeSpan: string): number {
  const parts = timeSpan.split('.');
  let day = 0; let time = parts[0];

  // If the timeSpan contains a day, adjust accordingly
  if (parts.length > 1) {
    day = parseInt(parts[0], 10); // get the day part
    time = parts[1]; // get the time part
  }

  const [hour, minute, second] = time.split(':').map(Number);

  // Convert to milliseconds from the start of the week
  const totalMilliseconds = (
    ((day * 24 + hour) * 60 + minute) * 60 + second
  ) * 1000;

  return totalMilliseconds;
}

// Helper function to get milliseconds from the start of the week for a given Date
function getMillisecondsFromWeekStart(date: Date): number {
  const day = (date.getDay() + 6) % 7; // Adjust JS week (Sunday=0) to match the required format (Monday=0)
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const totalMilliseconds = (
    ((day * 24 + hours) * 60 + minutes) * 60 + seconds
  ) * 1000;

  return totalMilliseconds;
}

// Function to check if a date is within a given WeekPeriod
export function isAvailableForDate(periods: WeekPeriod[], date: Date): boolean {
  const targetTime = getMillisecondsFromWeekStart(date);

  return periods.some((period) => {
    const startTime = parseTimeSpan(period.Start);
    const endTime = parseTimeSpan(period.End);

    // Check if the period crosses over into the next day
    if (endTime < startTime) {
      // If it does, split the check into two parts
      return (targetTime >= startTime || targetTime <= endTime);
    } else {
      // Normal case: check if target time falls between start and end times
      return targetTime >= startTime && targetTime <= endTime;
    }
  });
}
