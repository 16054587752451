import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { GlobalConfig, ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AnalyticsProvider } from '@app/shared/services/analytics.service';
import { AndroWebErrorService } from '@app/core/AndroWebError.service';
import { AndrowebErrorHandler } from '@app/core/ErrorHandler';
import { ApiTimesInterceptor } from '@app/interceptors/apiTimes.interceptor';
import { ApiService } from '@app/api/root/api.service';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationComponent } from '@app/navigation/navigation.component';
import { Response401Interceptor } from '@app/interceptors/response401.interceptor';
import { SEOService } from '@app/shared/services/seo.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedComponentsModule } from '@app/shared/components/shared-components.module';
import { SiteResolver } from '@app/core/site.service.resolver';
import { ToastsService } from '@app/shared/services/toasts.service';
import { TokenInterceptor } from '@app/interceptors/token.interceptor';
import { UserService } from '@app/api/user.service';
import { AppInjectorModule } from '@app/app-injector.module';
import { environment } from '../environments/environment'; // outside @app
import { MenuService } from '@app/api/menu.service';
import { IdentityIframeComponent } from '@app/shared/components/identity-iframe/identity-iframe.component';
import { ConfigurationInit } from '@app/app-initialisers/configuration-service/configuration.provider';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { GoogleAnalyticServiceInit } from '@app/app-initialisers/google-analytics-service/google-analytics.provider';
import { GoogleAnalyticService } from '@app/app-initialisers/google-analytics-service/google-analytics.service';
import { InitialiseLoginService } from '@app/app-initialisers/initialise-login/initialise-login.service';
import { InsightsService } from '@app/app-initialisers/insights-service/insights.service';
import { InsightsServiceInit } from '@app/app-initialisers/insights-service/InsightsServiceInit';
import { LoginInit } from '@app/app-initialisers/initialise-login/initialise-login.provider';
import { LayoutComponent } from '@app/layout/layout.component';

const toastrModuleConfig: Partial<GlobalConfig> = {
  autoDismiss: true,
  closeButton: true,
  countDuplicates: true,
  maxOpened: 1,
  preventDuplicates: true,
  progressBar: true,
  tapToDismiss: true,
  timeOut: 3000
};

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    NavigationComponent,
    IdentityIframeComponent,
    LayoutComponent
  ],
  imports: [
    AppRoutingModule,
    AppInjectorModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    SharedComponentsModule,
    ToastrModule.forRoot(toastrModuleConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    ApiService,
    AndroWebErrorService,
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: ApiTimesInterceptor },
    ConfigurationService,
    {
      deps: [ConfigurationService, AppInjectorModule],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: ConfigurationInit
    },
    { provide: ErrorHandler, useClass: AndrowebErrorHandler },
    GoogleAnalyticService,
    {
      deps: [GoogleAnalyticService, AppInjectorModule],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: GoogleAnalyticServiceInit
    },
    { multi: true, provide: AnalyticsProvider, useExisting: GoogleAnalyticService },
    InsightsService,
    {
      deps: [InsightsService, AppInjectorModule],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: InsightsServiceInit
    },
    { multi: true, provide: AnalyticsProvider, useExisting: InsightsService },
    InitialiseLoginService,
    {
      deps: [InitialiseLoginService, AppInjectorModule],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: LoginInit
    },
    MatIconRegistry,
    MenuService,
    { provide: OAuthStorage, useValue: localStorage },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: Response401Interceptor },
    SEOService,
    SiteResolver,
    ToastsService,
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor },
    UserService
  ]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }
}
