import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable, BehaviorSubject, of, throwError, from, lastValueFrom } from 'rxjs';
import { catchError, filter, take, tap, shareReplay, switchMap, finalize } from 'rxjs/operators';
import { Basket } from '@app/models/basket';
import { ToastsService } from '@app/shared/services/toasts.service';
import { MenuService } from '@app/api/menu.service';
import { UserService } from '@app/api/user.service';
import { HttpCodes } from '@app/models/http-codes';
import { Contact } from '@app/models/contact';
import { BasketAvailableDates } from '@app/models/basket-available-dates';
import { BasketAvailableTime } from '@app/models/basket-available-time';
import { BasketItem } from '@app/models/basket/basket-item';
import { NewVoucher } from '@app/models/new-voucher';
import { OptimisedBasket } from '@app/models/optimised-basket';
import { OrderOccasion } from '@app/models/order-occasion';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { HttpStatusCodeHandler } from '@app/core/http.status.codes';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { IDaysTimeSlots } from '@app/models/wanted-time-picker/IDaysTimeSlots';
import { IBasketDealIn } from '@app/models/basket/IBasketDealIn';
import { ApiExtendedService } from './root/api-extended.service';
import { BasketItemCreate } from '@app/models/basket/BasketItemCreate';
import { BasketEventTelemetry } from '@app/models/app-initialisers/BasketEventTelemetry';
import { BasketErrorTelemetry } from '@app/models/app-initialisers/BasketErrorTelemetry';

@Injectable({
  providedIn: 'root'
})
export class BasketService extends ApiExtendedService {
  public currentBasket$: Observable<Basket>;
  public currentBasket: BehaviorSubject<Basket>;
  public postcodeWithinDelivery: Observable<boolean>;

  private _loyaltyBusy: boolean = false;
  private _postcodeWithinDelivery: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _getCurrentBasketInProgress: any;
  private _basketCreatedSubject = new BehaviorSubject<Basket | null>(null);

  constructor(
    private _userService: UserService,
    private _menuService: MenuService,
    private _toastsService: ToastsService,
    @Inject(LOCAL_STORAGE) private _storage: StorageService
  ) {
    super();
    this.currentBasket = new BehaviorSubject<Basket>(null);
    this.currentBasket$ = this.currentBasket.asObservable();
    this.postcodeWithinDelivery = this._postcodeWithinDelivery.asObservable();
  }

  /**
   * updates the basket on the API to the given basket
   * @param basket
   */
  public updateBasket(basket: Basket): Observable<Basket | HttpErrorResponse> {
    const opBasket: OptimisedBasket = {
      Deals: basket.Deals,
      DeliveryLocation: basket.DeliveryLocation,
      IsValid: basket.IsValid,
      Items: basket.Items,
      Occasion: basket.Occasion,
      OrderId: basket.OrderId,
      SiteId: basket.SiteId,
      WantedTimeUtc: basket.WantedTimeUtc
    };

    return this.putResource<Basket, OptimisedBasket>(`baskets/${basket.Id}`, opBasket)
        .pipe(
            tap((response: Basket) => {
              this.handleBasketRefresh(response, 'updateBasket');
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status === HttpCodes.NotFound) {
                return this.clearOldAndCreateNewBasket(basket.SiteId);
              } else {
                this.trackException(error, false);
              }

              return throwError(() => error);
            })
        );
  }

  /**
   * returns the current basket for the given site Id or creates a new basket if one does not exist already
   * @param siteId the current site's id
   * @param occasion the wanted order occasion when creating a new basket
   * @param deliveryPostCode the delivery postcode when creating a new basket
   * @param retried the amount of times it's retried and delayed when the basket busy
   */
  public getCurrentBasketBySiteId(siteId: string, occasion?: OrderOccasion, deliveryPostCode?: string): Observable<Basket> {
    // return of(mockData as any);

    if (this._getCurrentBasketInProgress) {
      return this._basketCreatedSubject.asObservable()
          .pipe(
              filter((basket: Basket) => basket !== null), // Only pass through non-null values
              take(1) // Complete after the first non-null value
          );
    }

    this._getCurrentBasketInProgress = true;
    const basket: Basket = this.getBasketFromStorage(siteId);
    const basketObservable: Observable<Basket> = basket
      ? this.loadCurrentBasketFromServer(basket.Id, siteId)
      : from(this.clearOldAndCreateNewBasket(siteId, null, occasion, deliveryPostCode));

    return basketObservable.pipe(
        tap((x: Basket) => this.completeBasketCreation(x)),
        catchError((error) => {
          this._getCurrentBasketInProgress = false;
          return throwError(() => error);
        }),
        shareReplay(1) // Ensure that the result is multicast to all subscribers
    );
  }

  /**
   * calls `createANewBasket` and if successful updates the basket in local storage and the current basket in memory
   * @param siteId
   * @param basket
   * @param occasion
   * @param postCode
   */
  public async createAndUpdateBasket(siteId: string, basket?: Basket, occasion?: OrderOccasion, postCode?: string): Promise<Basket> {
    const response: Basket = await this.createANewBasket(siteId, basket, occasion, postCode);
    this.handleBasketRefresh(response, 'createAndUpdateBasket');
    return response;
  }

  /**
   * Removes the basket for the current site from local storage and sets the current basket to null
   * @param siteId
   */
  public clearCurrentBasket(siteId: string): void {
    this._storage.remove(`basket_${siteId.toLocaleLowerCase()}`);
    this.currentBasket.next(null);
  }

  /**
   * Clears the existing basket if one exists and creates a new basket for the given site ID and sets it as the current basket
   * @param siteId The site ID to create the new basket for.
   */
  public async clearOldAndCreateNewBasket(siteId: string, basket?: Basket, occasion?: OrderOccasion, postCode?: string): Promise<Basket> {
    this.clearCurrentBasket(siteId);
    return await this.createAndUpdateBasket(siteId, basket, occasion, postCode);
  }

  /**
   * returns the current basket if one exists, if not, it creates and returns a new basket.
   * @param basketId
   * @param caller
   * @param siteId
   */
  public loadCurrentBasketFromServer(basketId: string, siteId?: string): Observable<Basket> {
    return this.getResource<Basket>(`baskets/${basketId}`)
        .pipe(
            tap((response: Basket) => {
              this.refreshBasket(response);
            }),
            catchError(async (error: HttpErrorResponse) => {
              this.trackException(error, false);

              if (siteId && error.status === HttpCodes.NotFound) {
                return await this.clearOldAndCreateNewBasket(siteId);
              } else {
                throwError(() => error);
              }
            })
        );
  }

  /**
   * Updates the wanted occasion for the current basket. If the current basket is not set,
   * it attempts to retrieve it by siteId. Updates the occasion and delivery post code
   * of the basket and saves the changes.
   *
   * @param siteId The site ID to retrieve the basket if the current one is not set
   * @param occasion The occasion to set for the basket
   * @param deliveryPostCode The delivery post code to set for the basket
   * @returns A Promise that resolves when the operation is complete or rejects with an error
   */
  public setOccasionOfCurrentBasket(siteId: string, occasion: OrderOccasion, deliveryPostCode?: string): Promise<HttpErrorResponse | Basket> {
    const updateBasketAndResolve = (basket: Basket): Promise<HttpErrorResponse | Basket> => {
      basket.Occasion = occasion;
      basket.DeliveryLocation = deliveryPostCode;
      return lastValueFrom(this.updateBasket(basket));
    };

    let basket$: Observable<Basket>;

    if (this.currentBasket.value?.Id) {
      basket$ = of(this.currentBasket.value);
    } else {
      basket$ = this.getCurrentBasketBySiteId(siteId).pipe(
          tap((basket: Basket) => {
            if (!basket) {
              throw new Error('null basket');
            }
          })
      );
    }

    return lastValueFrom(
        basket$.pipe(
            switchMap((basket: Basket) => updateBasketAndResolve(basket)),
            catchError((error: HttpErrorResponse) => {
              this.trackTrace(`setOccasionOfCurrentBasket error: ${error.message}`, SeverityLevel.Error);
              return throwError(() => error);
            })
        )
    );
  }

  /**
   * Adds a product to the current basket
   * @param item
   * @param name
   */
  public async addProductToBasket(item: BasketItemCreate, name: string): Promise<HttpErrorResponse | HttpResponse<Basket>> {
    const basket: Basket = this.currentBasket.value;
    const response: HttpErrorResponse | HttpResponse<Basket> = await this.postResource<Basket, BasketItemCreate>(`baskets/${basket.Id}/items`, item);

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      this.handleBasketRefresh(response['body'], 'addProductToBasket');
      this.insightsService.trackEvent(new BasketEventTelemetry(basket.Id, 'Add', item.Product.Item, name));
      this._toastsService.emitNotification(name, ToastTypes.success, `${name} has successfully been added to your basket`, 'Yay');
    } else {
      this.trackTrace(`Error adding product to basket: ${response['message']}`, SeverityLevel.Error);
      this._toastsService.showToast(ToastTypes.error, 'Could not add item', 'Sorry!');
    }

    return response;
  }

  /**
   * updates the quantity of a product in the basket
   * @param itemId
   */
  public updateProductQuantity(itemId: string, newQuantity: number): Observable<Basket> {
    const basket: Basket = this.currentBasket.value;
    return this.putResource<Basket, { NewQuantity: number }>(`baskets/${basket.Id}/items/${itemId}`, { NewQuantity: newQuantity })
        .pipe(
            tap((response: Basket) => {
              this.handleBasketRefresh(response, 'updateProductQuantity');
            })
        );
  }

  /**
   * returns a basket with a matching Id as the one given
   * @param basketId
   */
  public getABasketById(basketId: string, refresh?: boolean): Observable<Basket | HttpErrorResponse> {
    return this.getResource<Basket>(`baskets/${basketId}`)
        .pipe(
            tap((response: Basket) => {
              if (!refresh) {
                return;
              }

              this.handleBasketRefresh(response, 'getABasketById');
            })
        );
  }

  /**
   * returns the available order times for a given basket and date
   * @param basketId
   * @param date
   * @param ignoreItemsInBasket
   */
  public getBasketsAvailableTimes(basketId: string, date: Date, ignoreItemsInBasket: boolean): Observable<BasketAvailableTime[]> {
    let queryParams = new HttpParams();

    if (ignoreItemsInBasket) {
      queryParams = queryParams.append('ignoreItemsInBasket', 'true');
    }

    return this.getResource<BasketAvailableTime[]>(`baskets/${basketId}/${this.getDateAsString(date)}/occasion-times`, queryParams) as Observable<BasketAvailableTime[]>;
  }

  /**
   * returns the available order dates for a given basket
   * @param basketId
   * @param ignoreItemsInBasket
   */
  public getBasketsAvailableDates(basketId: string, ignoreItemsInBasket: boolean): Observable<BasketAvailableDates> {
    let queryParams = new HttpParams();

    if (ignoreItemsInBasket) {
      queryParams = queryParams.append('ignoreItemsInBasket', 'true');
    }

    return this.getResource<BasketAvailableDates>(`baskets/${basketId}/occasion-dates`, queryParams) as Observable<BasketAvailableDates>;
  }

  /**
   * returns the available order times for a given basket and date
   * @param basketId
   * @param ignoreItemsInBasket
   */
  public getTimeSlotsForBasket(basketId: string, ignoreItemsInBasket: boolean): Promise<IDaysTimeSlots> {
    let params = new HttpParams();

    if (ignoreItemsInBasket) {
      params = params.append('ignoreItemsInBasket', 'true');
    }

    return lastValueFrom(this.getResource<IDaysTimeSlots>(`baskets/${basketId}/timeslots`, params)) as Promise<IDaysTimeSlots>;
  }

  /**
   * updates the wanted time for the given basket
   * @param basketId
   * @param payload
   */
  public async updateBasketWantedTime(basketId: string, payload: { value: string }): Promise<Basket | HttpErrorResponse> {
    const response: HttpErrorResponse | Basket = await lastValueFrom(this.putResource<Basket, { value: string }>(`baskets/${basketId}/wanted-time`, payload));

    if (!(response instanceof HttpErrorResponse)) {
      this.handleBasketRefresh(response, 'updateBasketWantedTime');
    }

    return response;
  }

  /**
   * attaches the customers Id to the given basket
   * @param basketId
   * @param customerId
   */
  public async setCustomerOnBasket(basketId: string, customerId: string): Promise<void> {
    await this.postResource(`baskets/${basketId}/customer/${customerId}`, null);

    const basket: Basket = await lastValueFrom(this.loadCurrentBasketFromServer(basketId));

    if (basket) {
      this.saveBasketToStorage(basket, 'setCustomerOnBasket');
    }
  }

  /**
 * Redeems loyalty points on the given basket.
 * This method ensures no concurrent loyalty operations are performed.
 * If the operation is in progress, it returns an observable that errors out.
 *
 * @param basketId The ID of the basket
 * @param points The number of loyalty points to redeem
 * @returns An Observable that emits upon completion of the loyalty points redemption.
 */
  public redeemLoyaltyOnBasket(basketId: string, points: number): Observable<Basket> {
    if (this._loyaltyBusy) {
      return null;
    }

    this._loyaltyBusy = true;

    const params = new HttpParams().set('points', points.toString());

    return this.putResource<null, null>(`baskets/${basketId}/loyalty`, null, params).pipe(
        switchMap(() => this.loadCurrentBasketFromServer(basketId)),
        tap((basket: Basket) => {
          this.saveBasketToStorage(basket, 'redeemLoyaltyOnBasket');
        }),
        finalize(() => {
          this._loyaltyBusy = false;
        })
    );
  }

  /**
   * adds a voucher to the current basket
   * @param basketId
   * @param voucher
   */
  public async addVoucherToBasket(basketId: string, voucher: NewVoucher): Promise<HttpErrorResponse | HttpResponse<Basket>> {
    const response: HttpErrorResponse | HttpResponse<Basket> = await this.postResource<Basket, NewVoucher>(`baskets/${basketId}/voucher`, voucher);

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      this.handleBasketRefresh(response['body'], 'addVoucherToBasket');
    }

    return response;
  }

  /**
   * removes the added voucher on the current basket
   * @param basketId
   */
  public async deleteVoucherFromBasket(basketId: string): Promise<Basket | null> {
    const response: HttpErrorResponse | HttpResponse<Basket> = await this.deleteResource<Basket>(`baskets/${basketId}/voucher`);

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      this.handleBasketRefresh(response['body'], 'deleteVoucherFromBasket');
      return response['body'];
    }

    return null;
  }

  /**
   * adds a deal to the current basket.
   * @param deal
   */
  public async addDealToBasketAsync(deal: IBasketDealIn): Promise<HttpErrorResponse | HttpResponse<Basket>> {
    const basket: Basket = this.currentBasket.value;
    const response: HttpErrorResponse | HttpResponse<Basket> = await this.postResource<Basket, IBasketDealIn>(`baskets/${basket.Id}/deals`, deal);

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      this.handleBasketRefresh(response['body'], 'addDealToBasketAsync');
    }

    return response;
  }

  /**
   * removes a deal from the current basket.
   * @param dealId
   */
  public async deleteDealFromBasketAsync(dealId: string): Promise<HttpErrorResponse | HttpResponse<Basket>> {
    const basket: Basket = this.currentBasket.value;
    const response: HttpErrorResponse | HttpResponse<Basket> = await this.deleteResource<Basket>(`baskets/${basket.Id}/deals/${dealId}`);

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      this.handleBasketRefresh(response['body'], 'deleteDealFromBasketAsync');
    }

    return response;
  }

  /**
   * updates the `postcodeWithinDelivery` observable ti the given value
   * @param value
   */
  public updatePostcodeWithinDelivery(value: boolean): void {
    this._postcodeWithinDelivery.next(value);
  }

  /**
  * updates the current baskets status
  * @param basket
  */
  public refreshBasket(basket: Basket): void {
    this.currentBasket.next(basket);
  }

  /**
   * sets the table number for the given basket
   * @param basketId
   * @param payload
   */
  public async setBasketDineInTableNumber(basketId: string, payload: { Value: number }): Promise<HttpErrorResponse | Basket> {
    const response: HttpErrorResponse | Basket = await lastValueFrom(this.putResource<Basket, { Value: number }>(`baskets/${basketId}/table`, payload));

    if (!(response instanceof HttpErrorResponse)) {
      this.handleBasketRefresh(response, 'setBasketDineInTableNumber');
    }

    return response;
  }

  /**
   * saves the given basket to local storage
   * @param basket
   * @param caller the preceding method up the stack
   */
  private saveBasketToStorage(basket: Basket, caller: string): void {
    if (basket?.SiteId) {
      this._storage.set(`basket_${basket?.SiteId.toLocaleLowerCase()}`, basket);
    } else {
      this.trackTrace(`${caller} tried to saveBasketToStorage with empty basket.`, SeverityLevel.Error);
    }
  }

  /**
   * creates a new basket for the given site
   * @param siteId
   * @param basket
   * @param occassion
   * @param deliveryPostCode
   * @param retired
   */
  private async createANewBasket(siteId: string, basket?: Basket, occassion?: OrderOccasion, deliveryPostCode?: string): Promise<Basket> {
    if (!basket) {
      basket = new Basket();
      basket.SiteId = siteId;
      basket.Occasion = occassion ? occassion : OrderOccasion.Delivery;
      basket.Items = [];
      basket.DeliveryLocation = occassion === OrderOccasion.Delivery ? (deliveryPostCode ?? this.getDefaultContactsPostcode()) : null;
    }

    const response: HttpErrorResponse | HttpResponse<Basket> = await this.postResource<Basket, Basket>('baskets', basket);

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      return response['body'];
    }

    this.insightsService.trackEvent(new BasketErrorTelemetry(basket.Id, 'Error', response['message'], response.status.toString()));
    return Promise.reject(response);
  }

  /**
   * removes a product from the basket
   * @param basket
   * @param basketItemId
   * @param allItems
   */
  private removeBasketItem(basket: Basket, basketItemId: string, allItems: boolean): void {
    const foundItems = basket.Items.filter((item) => item.Id === basketItemId);

    if (foundItems?.length > 0) {
      if (foundItems[0].Product.Quantity === 1 || allItems) {
        basket.Items = basket.Items.filter((item: BasketItem) => item.Id !== basketItemId);
      } else {
        foundItems[0].Product.Quantity--;
      }
    }
  }

  /**
   * returns the default contacts postcode
   * @param deliveryPostCode
   */
  private getDefaultContactsPostcode(): string {
    return this._userService.currentUser?.Contacts?.find((c: Contact) => c.IsDefault)?.PostCode;
  }

  /**
   * returns a basket with matching siteId from local storage
   * @param siteId
   */
  private getBasketFromStorage(siteId?: string): Basket {
    return siteId ? this._storage.get(`basket_${siteId.toLocaleLowerCase()}`) : null;
  }

  /**
   * returns the given date as a utc time string
   * @param date
   */
  private getDateAsString(date: Date): string {
    const month: string = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : JSON.stringify(date.getMonth() + 1);
    const day: string = (date.getDate()) < 10 ? `0${(date.getDate())}` : JSON.stringify(date.getDate());
    const hours: string = (date.getHours()) < 10 ? `0${(date.getHours())}` : JSON.stringify(date.getHours());
    const minutes: string = (date.getMinutes()) < 10 ? `0${date.getMinutes()}` : JSON.stringify(date.getMinutes());

    return `${date.getFullYear()}-${month}-${day}T${hours}:${minutes}:00z`;
  }

  private completeBasketCreation(basket: Basket): void {
    this._getCurrentBasketInProgress = false;
    this._basketCreatedSubject.next(basket);
  }

  private handleBasketRefresh(basket: Basket, callerName: string): void {
    this.refreshBasket(basket);
    this.saveBasketToStorage(basket, callerName);
  }
}
