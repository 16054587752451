import { Router } from '@angular/router';
import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Site } from '@app/models/site';
import { Contact } from '@app/models/contact';
import { User } from '@app/models/user';
import { Basket } from '@app/models/basket';
import { UserService } from '@app/api/user.service';
import { MenuService } from '@app/api/menu.service';
import { SiteService } from '@app/api/site.service';
import { BasketService } from '@app/api/basket.service';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { ToastsService } from '@app/shared/services/toasts.service';
import { BasketItem } from '@app/models/basket/basket-item';
import { ChargeTypes } from '@app/models/charge-types';
import { Issue } from '@app/models/Issue';
import { IssueTypes } from '@app/models/issue-types';
import { OrderOccasion } from '@app/models/order-occasion';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { NavigatorService } from '@app/core/navigator.service';
import { NavigationSectionType } from '@app/models/navigation/navigation-section-type';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { lastValueFrom, takeUntil } from 'rxjs';
import { CustomerLoyaltyState } from '@app/models/customer-loyalty-state';
import { BasketDealView } from '@app/models/basket/basket-deal-view';
import { BasketItemView } from '@app/models/basket/basket-item-view';
import { MenuHelperService } from '@app/shared/services/menu-helper/menu-helper.service';
import { BasketDeal } from '@app/models/basket/basket-deal';

@Component({
  selector: 'basket-component',
  styleUrls: ['./basket.component.scss'],
  templateUrl: './basket.component.html'
})
export class BasketComponent extends AndroWebCoreComponent implements OnInit {
  @Output() private allDone = new EventEmitter<null>();

  @Input() public displayTitle: boolean = true;
  @Input() public showBasketToggle: boolean = true;
  @Input() public showCheckout: boolean;
  @Input() public disableBasketEditing: boolean;
  @Input() public orderComplete: boolean;
  @Input() public displayChargesOnly: boolean;
  @Input() public disableVoucherEditing: boolean;

  public orderOccasion = OrderOccasion;
  public currentBasket: Basket;
  public isThankYouPage: boolean;
  public basketIsModifiable: boolean;
  public showOccasionsToggle: boolean;
  public supportsBothOccasions: boolean;
  public postCodeWithinDelivery: boolean;
  public basketTotal: number;
  public loyaltyDiscount: number;
  public fastestDeliveryTime: number;
  public fastestCollectionTime: number;
  public user: User;
  public currentSite: Site;
  public earnablePointsForCurrentBasket: number;
  public usersLoyaltyPoints: number;
  public dealItems: BasketDealView[] = [];
  public productItems: BasketItemView[] = [];

  private isUpdatingBasket: boolean;
  private _userWelcomePoints: number;

  constructor(
    private router: Router,
    private userService: UserService,
    private menuService: MenuService,
    private siteService: SiteService,
    private _userService: UserService,
    private basketService: BasketService,
    private toastsService: ToastsService,
    private navigatorService: NavigatorService,
    private analyticsService: AnalyticsService,
    private _menuHelperService: MenuHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.trackUserLoyaltyPoints();

    // postCodeWithinDelivery enables/disabled delivery occasion toggle at the top of the basket
    this.basketService.postcodeWithinDelivery
        .pipe(takeUntil(this.destroy$))
        .subscribe((value: boolean) => {
          this.postCodeWithinDelivery = value;
        });

    this.isThankYouPage = this.router.url.startsWith('/checkout') && this.router.url.includes('/thank-you');

    this.setupSiteAndServiceEstimates();
    this.setupBasketAndMenu();

    this.userService.currentUser$
        .pipe(takeUntil(this.destroy$))
        .subscribe((user: User) => this.user = user);
  }

  /**
  * if there are no minimum spend issues and the user isn't logged in it navigates to login page or it navigates to checkout
  * But if there are minimum spend issues it displays a friendly toast to the user explaining that they have minimum spend issues
  */
  public goToCheckout(): void {
    if (!this.user?.Id) {
      this.login();
      return;
    }

    const basketIssues = this.currentBasket.Issues.filter((issue) => issue.IssueType === IssueTypes.TotalIsLessThanMinimumSpend);

    if (basketIssues.length > 0) {
      this.toastsService.showToast(ToastTypes.error, 'Sorry - Minimum spend requirements have not been met. Please add more items and try again');
      return;
    }

    if (this.currentBasket.Items.length > 0 || this.currentBasket.Deals.length > 0) {
      this.analyticsService.pageView('/initiateCheckout', 'Logged in');
      this.closeBasket();
      this.router.navigate(['/checkout', this.currentBasket.Id]);
    }
  }

  /**
  * closes the basket modal
  */
  public closeBasket(): void {
    this.allDone.emit();
  }

  /**
  * updates the occasion of the current basket
  * @param $event - a MatButtonToggleChange event with the order occasion to be used
  */
  public toggleBasketOccasionChange($event: MatButtonToggleChange): void {
    if (this.currentBasket.Occasion === $event.value) {
      return;
    }

    if (this.currentBasket.CompatibleOccasions.length === 0 || this.currentBasket.CompatibleOccasions.includes($event.value)) {
      this.currentBasket.Occasion = ($event.value as OrderOccasion);

      let defaultContact: Contact;

      if (this.user && ($event.value as OrderOccasion) === OrderOccasion.Delivery) {
        defaultContact = this.user.Contacts.find((contact: Contact) => contact.IsDefault);
      }

      this.basketService.setOccasionOfCurrentBasket(this.currentSite.Id, ($event.value as OrderOccasion), defaultContact?.PostCode);

      const location: string = this.navigatorService.getLocationBySiteId( this.currentSite.Id);
      const routePath: string = this.navigatorService.generateRoutePath(location, ($event.value as OrderOccasion), NavigationSectionType.Menu);
      this.router.navigate([routePath]);
    }
  }

  /**
  * determines whether the checkout button should be enabled or disabled
  */
  public shouldDisableCheckout(): boolean {
    const itemsWithIssues = this.currentBasket.Items
        .map((item: BasketItem) => item.Issues)
        .filter((issues: any) => issues?.length > 0);

    const allDealItems: BasketItem[] = this.currentBasket.Deals.map((item: BasketDeal) => item.Items).reduce((a, b) => a.concat(b), []);
    const dealItemsWithIssues = allDealItems?.filter((item: BasketItem) => item.Issues?.length > 0);

    const basketIssues: Issue[] = this.currentBasket.Issues?.filter((issue: Issue) =>
      issue.IssueType !== IssueTypes.TotalIsLessThanMinimumSpend
      && issue.IssueType !== IssueTypes.SiteDoesNotDeliverToDeliveryLocation
      && issue.IssueType !== IssueTypes.DeliveryOccasionRequiresDeliveryLocation
      && issue.IssueType !== IssueTypes.OccasionIsNotAvailableAtWantedTime
      && issue.IssueType !== IssueTypes.SiteIsNotAvailableForAsapOrdersNow
      && issue.IssueType !== IssueTypes.SiteDoesNotAcceptAsapOrdersForOccasion
    );

    return this.isUpdatingBasket || basketIssues?.length > 0 || itemsWithIssues?.length > 0 || dealItemsWithIssues?.length > 0;
  }

  /**
  * determines whether the you could earn text shows or not
  */
  public showEarnableLoyaltyPoints(): boolean {
    const maxPoints: number = this.tenant?.LoyaltyScheme?.EarningRules?.MaxPointsPerCustomer;
    return maxPoints ? this.usersLoyaltyPoints < maxPoints : true;
  }

  /**
   * gets the current site and find the fastest delivery & occasion times
   */
  private setupSiteAndServiceEstimates(): void {
    this.siteService.currentSite$
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (currentSite: Site) => {
          let fastestDeliveryTime: number;
          let fastestCollectionTime: number;

          if (currentSite) {
            this.currentSite = currentSite;

            fastestDeliveryTime = this.getFastestAvailableTime(currentSite.EstimatedDeliveryTime);
            fastestCollectionTime = this.getFastestAvailableTime(currentSite.EstimatedCollectionTime);
          } else {
            const sites: Site[] = await lastValueFrom(this.siteService.getSites());

            if (this.currentBasket) {
              const site: Site = sites.find((s: Site) => s.Id === this.currentBasket.SiteId);

              if (site) {
                this.currentSite = site;
                fastestDeliveryTime = this.getFastestAvailableTime(site.EstimatedDeliveryTime);
                fastestCollectionTime = this.getFastestAvailableTime(site.EstimatedCollectionTime);
              }
            }
          }

          if (this.currentSite?.OccasionsSupported) {
            this.supportsBothOccasions = this.currentSite.OccasionsSupported.includes(OrderOccasion.Collection)
        && this.currentSite.OccasionsSupported.includes(OrderOccasion.Delivery);
          } else {
            this.supportsBothOccasions = this.tenant.OccasionsSupported.includes(OrderOccasion.Collection)
        && this.tenant.OccasionsSupported.includes(OrderOccasion.Delivery);
          }

          this.fastestDeliveryTime = fastestDeliveryTime ? fastestDeliveryTime : 30;
          this.fastestCollectionTime = fastestCollectionTime ? fastestCollectionTime : 15;
        });
  }

  /**
   * uses baskets charges & wanted time to update the dom, and checks if there are any issues.
   */
  private setupBasketAndMenu(): void {
    this.basketService.currentBasket
        .pipe(takeUntil(this.destroy$))
        .subscribe((basket: Basket) => {
          if (!basket) {
            return;
          }

          if (this.router.url.toLowerCase().includes('/menu')) {
            basket.IsModifiable = true;
          }

          this.currentBasket = basket;
          this.basketTotal = this.getBasketCharge(basket, ChargeTypes.Total);
          this.loyaltyDiscount = Math.abs(this.getBasketCharge(basket, ChargeTypes.LoyaltyPointsDiscount));
          this.earnablePointsForCurrentBasket = this.getBasketEarnablePoints();

          this.basketIsModifiable = (!this.disableBasketEditing && basket.IsModifiable);

          if (this.currentBasket.WantedTimeUtc) {
            this.currentBasket.WantedTimeUtc = this.replaceZuluTime(basket.WantedTimeUtc);
          }

          const cachedMenu = { menu: this.menuService.menuValue, optionsById: this.menuService.productOptionsById, variantsById: this.menuService.productVariantsById };
          const data = this._menuHelperService.getBasketItemViews(basket, cachedMenu);
          this.dealItems = data.deals;
          this.productItems = data.products;
          this.checkBasketItemsOccasions();
        });
  }

  /**
  * emits a return url string in an event that initiates the login process
  */
  private login(): void {
    const hasMinSpendIssues = this.currentBasket.Issues.filter((issue) => issue.IssueType === IssueTypes.TotalIsLessThanMinimumSpend).length > 0;
    this.analyticsService.pageView('/initiateCheckout', 'Not logged in');
    this.userService.login(false, hasMinSpendIssues ? `/menu/${this.currentSite.Id}/${this.currentBasket.Occasion}` : `/checkout/${this.currentBasket.Id}`);
    this.closeBasket();
  }

  /**
  * returns the estimated time as a number in terms of minutes
  * @param estimatedTime - the fasted estimated time
  */
  private getFastestAvailableTime(estimatedTime: string): number {
    const splitTime = estimatedTime.split(':');
    const time = (Number(splitTime[0]) * 3600) + (Number(splitTime[1]) * 60) + Number(splitTime[2]);

    return (time / 60);
  }

  /**
  * checks if all items in the basket at compatible for both order occasions
  */
  private checkBasketItemsOccasions(): void {
    const result = {
      both: [],
      collection: [],
      delivery: [],
      empty: []
    };

    this.dealItems.forEach((deal: BasketDealView) => {
      if (deal.occasions.includes(OrderOccasion.Delivery) && deal.occasions.includes(OrderOccasion.Collection)) {
        result.both.push(deal);
      } else if (deal.occasions.includes(OrderOccasion.Delivery)) {
        result.delivery.push(deal);
      } else if (deal.occasions.includes(OrderOccasion.Collection)) {
        result.collection.push(deal);
      } else {
        result.empty.push(deal);
      }
    });

    this.productItems.forEach((product: BasketItemView) => {
      if (product.occasions.includes(OrderOccasion.Delivery) && product.occasions.includes(OrderOccasion.Collection)) {
        result.both.push(product);
      } else if (product.occasions.includes(OrderOccasion.Delivery)) {
        result.delivery.push(product);
      } else if (product.occasions.includes(OrderOccasion.Collection)) {
        result.collection.push(product);
      } else {
        result.empty.push(product);
      }
    });

    this.showOccasionsToggle = (this.dealItems.length + this.productItems.length) === result.both.length;
  }

  /**
  * returns the amount of a given charge on a given basket
  * @param basket - the basket
  * @param chargeType - the charge type that needs to be returned
  */
  private getBasketCharge(basket: Basket, chargeType: ChargeTypes): number {
    const amount = basket.Charges?.find((charge) => charge.ChargeType === chargeType)?.Price.Amount;
    return amount ? amount : 0;
  }

  /**
  * returns the amount of points a user can earn from their current basket
  */
  private getBasketEarnablePoints(): number {
    let potentialPoints = this.currentBasket.LoyaltyPointsValue + (this._userWelcomePoints ?? 0);
    const earningRules = this.tenant?.LoyaltyScheme?.EarningRules;

    if (earningRules?.MaxPointsPerCustomer) {
      const maxRemainingPoints: number = earningRules.MaxPointsPerCustomer - (this.usersLoyaltyPoints ?? 0);

      if (potentialPoints > maxRemainingPoints) {
        potentialPoints = maxRemainingPoints;
      }
    }

    return potentialPoints ?? 0;
  }

  /**
   * tracks the users loyalty points
   */
  private trackUserLoyaltyPoints(): void {
    this._userService.getUsersLoyalty()
        .pipe(takeUntil(this.destroy$))
        .subscribe((x: CustomerLoyaltyState) => {
          this.usersLoyaltyPoints = x.PointsBalance ?? 0;
          this._userWelcomePoints = x.WelcomePointsAvailable ?? 0;

          if (this.currentBasket) {
            this.earnablePointsForCurrentBasket = this.getBasketEarnablePoints();
          }
        });
  }
}
